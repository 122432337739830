import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from './components/Loadable';

const Layout = Loadable(lazy(() => import('./components/Layout')));
const Home = Loadable(lazy(() => import('./pages/Home')));
const Contact = Loadable(lazy(() => import('./pages/Contact')));
const Trade = Loadable(lazy(() => import('./pages/Trade')));
const Chart = Loadable(lazy(() => import('./pages/Chart')));

const Router = [
    {
        path: '/',
        element: <Layout/>,
        children: [
            { path: '/', element: <Home/> },
            { path: '/contact', element: <Contact/> },
            { path: '/trade', element: <Trade/> },
            { path: '/chart', element: <Chart/> },
            { path: '*', element: <Navigate to='/'/> }
        ]
    }
]

export default Router;