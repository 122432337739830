import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/index.css';
import App from './App';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  <Suspense>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Suspense>
    ,
  document.getElementById('root')
);